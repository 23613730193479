const accordionList = [
    {id: 0, title: "Brand"},
    {id: 1, title: "Serie"},
    {id: 2, title: "Type"},
    {id: 3, title: "Højde"},
    {id: 4, title: "Bredde"},
    {id: 5, title: "Dybbe"},
    {id: 6, title: "Farve"},
]

export default accordionList