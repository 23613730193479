import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './root.css'

import { Home,BestilVVSer ,OmOs, Products, Product, ProductView, Checkout } from './public/pages/index.js'
import { Container, Footer, Header} from './public/sections/index.js'

const App = () => {
    
    return (
        <>
            <Router>
                <Header />
                <Container>
                   <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/checkout' element={<Checkout/>}/>
                    <Route path='/bestil-vvser' element={<BestilVVSer/>}/>
                    <Route path='/om-os' element={<OmOs/>}/>
                    <Route path='/shop/:categoryUrl' element={<Products/>}/>
                    <Route path='shop/:categoryUrl/:productUrl' element={<Product/>}/>
                    <Route path='shop/:categoryUrl/:productUrl/:productNumber' element={<ProductView/>}/>
                    </Routes> 
                </Container>
                <Footer/>
            </Router>
         
        </>
    )
}

export default App